import { uniq } from '@/lib/utils/array';
import { FormularyItem, FormularyMedication } from '@/types/core/FormularyItem';
import { MedicationOrderTemplate } from '@/types/core/MedicationOrderTemplate';

const templateMatchesMedication = (
  template: MedicationOrderTemplate,
  medication: FormularyMedication,
) => {
  /**
   * For the Zepbound formulary item, we should only match auto-injector templates.
   * Likewise, zepbound vials should only match zepbound templates
   * that don't contain the word "auto-injector".
   */
  const matchersBySlug = {
    zepbound: /zepbound.*auto-injector/i,
    'zepbound-solution': /zepbound(?!.*auto-injector).*/i,
  };

  const matcher =
    matchersBySlug[medication.slug as keyof typeof matchersBySlug] ??
    new RegExp(medication.name, 'i');

  return matcher.test(template.name);
};

export const getFormularyItemNdcs = (
  item: FormularyItem,
  templates: MedicationOrderTemplate[],
) => {
  const matchedTemplates = templates.filter((template) =>
    templateMatchesMedication(template, item.medication),
  );
  return uniq(matchedTemplates.map(({ ndcs }) => ndcs).flat());
};

const cashPayNotes = [
  {
    medication: {
      name: 'Contrave',
      slug: 'contrave',
    },
    notes: 'Ridgeway and Pharmacy Arts',
  },
  {
    medication: {
      name: 'Zepbound Solution Vials',
      slug: 'zepbound-solution',
    },
    notes: 'LillyDirect Pharmacy',
  },
  {
    medication: {
      name: 'Wegovy',
      slug: 'wegovy',
    },
    notes: 'NovoCare for cash pay only, any pharmacy to pay through insurance.',
  },
] as const;

export const getCashPayNotes = (template: MedicationOrderTemplate) => {
  const notes = cashPayNotes.find(({ medication }) =>
    templateMatchesMedication(template, medication),
  );
  return notes?.notes;
};

export const cashPayItemsFromTemplates = ({
  formulary,
  templates,
  cashPayNdcs,
}: {
  formulary: FormularyItem[];
  templates: MedicationOrderTemplate[];
  cashPayNdcs?: string[];
}) => {
  const items = formulary.filter((item) => {
    const ndcs = getFormularyItemNdcs(item, templates);
    return ndcs.some((code) => cashPayNdcs?.includes(code));
  });

  items.push(ZEPBOUND_VIALS);

  return items.map((item) => {
    return { ...item, isCashPay: true };
  });
};

export const isApproved = ({
  template,
  formulary,
}: {
  template: MedicationOrderTemplate;
  formulary: FormularyItem[];
}) => {
  if (formulary.length === 0) {
    return true;
  }
  const unapprovedItems = [
    ...formulary.filter((item) => !item.isApproved),
    ZEPBOUND_VIALS,
  ];

  return !unapprovedItems.some(({ medication }) =>
    templateMatchesMedication(template, medication),
  );
};

export const isCashPay = ({
  template,
  cashPayNdcs,
  formulary,
}: {
  template: MedicationOrderTemplate;
  cashPayNdcs: string[];
  formulary: FormularyItem[];
}) => {
  return (
    (formulary.length === 0 || !isApproved({ template, formulary })) &&
    cashPayNdcs.length > 0 &&
    template.ndcs.some((ndc) => cashPayNdcs.includes(ndc))
  );
};

const ZEPBOUND_VIALS: FormularyItem = {
  medication: {
    slug: 'zepbound-solution',
    name: 'Zepbound Solution Vials',
  },
  isApproved: false,
  isLegacy: false,
  approvedByToc: false,
  isOnFormulary: false,
  requiresPriorAuthorization: false,
  conditions: [],
  minScore: null,
  isCashPay: true,
};
