import { useQuery } from '@tanstack/react-query';

import { fetchPatient } from '@/lib/api/core/fetchPatient';

import { isRetryable } from '../utils/error/isRetryable';

type UsePatientOptions = {
  refetchOnWindowFocus?: boolean;
  retry?: (failureCount: number, error: unknown) => boolean;
};

export const usePatient = (
  patientId?: string,
  options: UsePatientOptions = {},
) =>
  useQuery({
    queryFn: () => fetchPatient(patientId),
    queryKey: ['patient', patientId],
    enabled: !!patientId,
    retry: (failureCount, error) => isRetryable(error) && failureCount <= 3,
    ...options,
  });
